var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon icon-tabler icon-tabler-external-link",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": _vm.size,
      "height": _vm.size,
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": _vm.color,
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"
    }
  }), _c('path', {
    attrs: {
      "d": "M11 13l9 -9"
    }
  }), _c('path', {
    attrs: {
      "d": "M15 4h5v5"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }