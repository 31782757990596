<template>
  <div class="p-3">
    <div class="bg-white border border-gray-200 rounded-lg shadow">
      <div class="p-6 pb-2">
        <h5 class="text-black font-semibold text-md tracking-wider">
          {{ type }}
        </h5>
        <div class="flex justify-between items-center">
          <div
            class="font-normal text-gray-500 text-md tracking-wider flex items-center gap-2"
          >
            <span
              class="flex items-center"
              :class="{
                'text-green-500': rate > 0,
                'text-red-700': rate < 0,
              }"
            >
              <ArrowDownRight class="text-xs w-4 h-4" v-if="rate < 0" />
              <ArrowUpRight class="text-xs w-4 h-4" v-else-if="rate > 0" />

              {{ rate.toFixed(2) }}%
            </span>
            {{ period }}
          </div>
          <span
            class="text-center text-4xl font-semibold"
            :class="{
              'text-green-500': rate > 0,
              'text-red-700': rate < 0,
            }"
          >
            {{ count }}
          </span>
        </div>
      </div>

      <VueApexChart
        ref="realtimeChart"
        :options="CHART_OPTIONS"
        :series="[{ data: series, name: type }]"
        type="bar"
        height="100"
      />
    </div>
  </div>
</template>

<script>
import ArrowUpRight from "@/common/icons/ArrowUpRight.vue";
import ArrowDownRight from "@/common/icons/ArrowDownRight.vue";
import VueApexChart from "vue-apexcharts";
const CHART_OPTIONS = {
  chart: {
    type: "bar",
    width: 100,
    height: 10,
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "80%",
    },
  },

  xaxis: {
    type: "datetime",
  },
};
export default {
  name: "Rate",
  components: {
    VueApexChart,
    ArrowUpRight,
    ArrowDownRight,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    rate: {
      type: Number,
      default: 0,
    },

    count: {
      type: Number,
      default: 0,
    },
    series: {
      type: Array,
      default: () => [],
    },
    period: {
      type: String,
      default: "30",
    },
  },
  data() {
    return {
      CHART_OPTIONS,
    };
  },

  watch: {
    series() {
      this.$refs.realtimeChart.updateOptions({
        colors: [this.rate >= 0 ? "#10B981" : "#EF4444"],
        tooltip: {
          x: {
            format: this.series?.length === 12 ? "MM/yy" : (this.series?.length === 24 || this.series?.length === 23 ? "dd/MM/yy HH:mm" : "dd/MM/yy"),
          },
        },
      });
    },
    rate() {
      this.$refs.realtimeChart.updateOptions({
        colors: [this.rate >= 0 ? "#10B981" : "#EF4444"],
        // tooltip: {
        //   x: {
        //     format:
        //       this.series?.length === 12
        //         ? "MM/yyyy"
        //         : this.series?.length === 24
        //         ? "dd/MM/yyyy HH:mm"
        //         : "dd/MM/yyyy",
        //   },
        // },
      });
    },
  },
  mounted() {
    this.$refs.realtimeChart.updateOptions({
      colors: [this.rate >= 0 ? "#10B981" : "#EF4444"],
      // TODO: if tje length of series is greater than 30 then tooltip should be disabled
      tooltip: {
        x: {
          format: this.series?.length === 12 ? "MM/yy" : (this.series?.length === 24 || this.series?.length === 23 ? "dd/MM/yy HH:mm" : "dd/MM/yy"),
        },
        y: {
          formatter: (value) => value.toFixed(0),
        }
      },
    });
  },
};
</script>
