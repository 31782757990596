var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon icon-tabler icon-tabler-package",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": _vm.size,
      "height": _vm.size,
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": _vm.color,
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 12l8 -4.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 12l0 9"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 12l-8 -4.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 5.25l-8 4.5"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }