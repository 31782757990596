<script>
export default {
  name: "PackageIcon",
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-package"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    stroke-width="2"
    :stroke="color"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5"></path>
    <path d="M12 12l8 -4.5"></path>
    <path d="M12 12l0 9"></path>
    <path d="M12 12l-8 -4.5"></path>
    <path d="M16 5.25l-8 4.5"></path>
  </svg>
</template>
