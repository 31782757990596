<template>
  <div>
    <div v-if="isLoading" style="left: 50%; top: 3%; position: fixed; z-index: 9999"
      class="bg-gray-700 h-12 w-12 rounded flex items-center justify-center">
      <svg class="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
        stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 3a9 9 0 1 0 9 9"></path>
      </svg>
    </div>
    <div class="flex justify-between items-end my-5 px-6 flex-wrap gap-6">
      <div>
        <h1 class="text-xl font-semibold">Dashboard</h1>
        <div class="flex items-center text-lg gap-4" v-if="selectedProduct">
          Analytics and insights for Product:
          <span class="font-semibold">{{ selectedProduct?.name }}</span>
          <router-link :to="`/products/${selectedProduct._id}/analytics`">
            <Button icon="pi pi-link" class="p-button-rounded p-button-outlined" />
          </router-link>
        </div>
      </div>
      <div class="flex gap-4 flex-wrap">
        <Dropdown v-if="productTags.length" v-model="selectedProductTag" optionLabel="label" :options="productTags"
          showClear placeholder="Select Product Tag" class="w-full md:w-64" :disabled="isLoading" />
        <Dropdown v-model="selectedProduct" :options="products" optionLabel="name" showClear
          placeholder="Select a Product" class="w-full md:w-64" :disabled="isLoading" />
        <Vue2DatePicker v-model="selectedDateRange" class="w-full md:w-64" range placeholder="Select date range"
          :disabled-date="notAfterToday" :shortcuts="SHORTCUTS" :disabled="isLoading" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5 px-6">
      <Rate class="w-full p-2" type="Total Orders" :rate="ordersRateDetails.rates.total.rate"
        :count="ordersRateDetails.rates.total.current" :series="ordersRateDetails.series.total" :period="period" />
 
      <Rate class="w-full p-2" type="Delivered Orders" :rate="ordersRateDetails.rates.delivered.rate"
        :count="ordersRateDetails.rates.delivered.current" :series="ordersRateDetails.series.delivered"
        :period="period" />
      <Rate class="w-full p-2" type="Confirmed Orders" :rate="ordersRateDetails.rates.confirmed.rate"
        :count="ordersRateDetails.rates.confirmed.current" :series="ordersRateDetails.series.confirmed"
        :period="period" />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5 px-6">
      <div class="p-3">
        <div class="bg-white border border-gray-200 rounded-lg shadow p-6">
          <h2 class="text-xl font-semibold tracking-wide">
            Profits per delivery
          </h2>
          <div class="flex items-center gap-4 justify-between">
            <span class="text-3xl font-bold text-black" :class="{
              'text-green-500':
                costPerDeliveryDetails.costs.profitsPerDelivery > 0,
              'text-red-500':
                costPerDeliveryDetails.costs.profitsPerDelivery < 0,
            }">
              {{
                formatCurrency(costPerDeliveryDetails.costs.profitsPerDelivery)
              }}
            </span>
            <!-- <div>
              <ArrowDownRight
                class="w-12 h-12 text-red-600"
                v-if="costPerDeliveryDetails.costs.profitsPerDelivery < 0"
                :size="48"
              />
              <ArrowUpRight
                class="w-12 h-12 text-green-600"
                v-else-if="costPerDeliveryDetails.costs.profitsPerDelivery > 0"
                :size="16"
              />
            </div> -->
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="bg-white border border-gray-200 rounded-lg shadow p-6">
          <h2 class="text-xl font-semibold tracking-wide">Total Profits</h2>
          <div class="flex items-center gap-4 justify-between">
            <span class="text-3xl font-bold text-black" :class="{
              'text-green-500': costPerDeliveryDetails.costs.totalProfits > 0,
              'text-red-500': costPerDeliveryDetails.costs.totalProfits < 0,
            }">
              {{ formatCurrency(costPerDeliveryDetails.costs.totalProfits) }}
            </span>
            <!-- <div>
              <ArrowDownRight
                class="w-12 h-12 text-red-600"
                v-if="costPerDeliveryDetails.costs.totalProfits < 0"
                :size="48"
              />
              <ArrowUpRight
                class="w-12 h-12 text-green-600"
                v-else-if="costPerDeliveryDetails.costs.totalProfits > 0"
                :size="16"
              />
            </div> -->
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="bg-white border border-gray-200 rounded-lg shadow p-6">
          <h2 class="text-xl font-semibold tracking-wide">
            Return of investment
          </h2>
          <div class="flex items-center gap-4 justify-between">
            <span class="text-3xl font-bold text-black" :class="{
              'text-green-500': costPerDeliveryDetails.costs.roi > 0,
              'text-red-500': costPerDeliveryDetails.costs.roi < 0,
            }">
              {{ costPerDeliveryDetails.costs.roi }} %
            </span>
            <!-- <div>
              <ArrowDownRight
                class="w-12 h-12 text-red-600"
                v-if="costPerDeliveryDetails.costs.roi < 0"
                :size="48"
              />
              <ArrowUpRight
                class="w-12 h-12 text-green-600"
                v-else-if="costPerDeliveryDetails.costs.roi > 0"
                :size="16"
              />
            </div> -->
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="bg-white border border-gray-200 rounded-lg shadow p-6">
          <h2 class="text-xl font-semibold tracking-wide">
            Average order value
          </h2>
          <div class="flex items-center gap-4 justify-between">
            <span class="text-3xl font-bold text-black" :class="{
              'text-green-500': costPerDeliveryDetails.costs.aov > 0,
              'text-red-500': costPerDeliveryDetails.costs.aov < 0,
            }">
              {{ formatCurrency(costPerDeliveryDetails.costs.aov) }}
            </span>
            <!-- <div>
              <ArrowDownRight
                class="w-12 h-12 text-red-600"
                v-if="costPerDeliveryDetails.costs.aov < 0"
                :size="48"
              />
              <ArrowUpRight
                class="w-12 h-12 text-green-600"
                v-else-if="costPerDeliveryDetails.costs.aov > 0"
                :size="16"
              />
            </div> -->
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="bg-white border border-gray-200 rounded-lg shadow p-6">
          <h2 class="text-xl font-semibold tracking-wide">Revenues</h2>
          <div class="flex items-center gap-4 justify-between">
            <span class="text-3xl font-bold text-black" :class="{
              'text-green-500': costPerDeliveryDetails.costs.revenues > 0,
              'text-red-500': costPerDeliveryDetails.costs.revenues < 0,
            }">
              {{ formatCurrency(costPerDeliveryDetails.costs.revenues) }}
            </span>
            <!-- <div>
              <ArrowDownRight
                class="w-12 h-12 text-red-600"
                v-if="costPerDeliveryDetails.costs.revenues < 0"
                :size="48"
              />
              <ArrowUpRight
                class="w-12 h-12 text-green-600"
                v-else-if="costPerDeliveryDetails.costs.revenues > 0"
                :size="16"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 mt-5 px-6">
      <div class="">
        <div
          class="p-6 mb-6 space-y-4 md:space-y-0 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div>
            <h2 class="text-xl font-semibold tracking-wide mb-4">
              Cost per delivery
            </h2>
            <div class="flex justify-between gap-6 flex-wrap mb-6">
              <div>
                <h3 class="text-lg font-semibold">Total</h3>
                <span class="text-2xl font-bold text-black">
                  {{ formatCurrency(costPerDeliveryDetails.costs.CPDTotal) }}
                </span>
              </div>
              <div>
                <h3 class="text-lg font-semibold">ADS</h3>
                <span class="text-2xl font-bold text-black">
                  {{ formatCurrency(costPerDeliveryDetails.costs.CPDAds) }}
                </span>
              </div>
              <div>
                <h3 class="text-lg font-semibold">Charges</h3>
                <span class="text-2xl font-bold text-black">
                  {{ formatCurrency(costPerDeliveryDetails.costs.CPDCharges) }}
                </span>
              </div>
            </div>
            <VueApexCharts type="bar" height="350" :options="_chartOptions" :series="costPerDeliverySeries" />
          </div>
        </div>
      </div>
      <div>
        <slot name="left"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Rate from "./Analytics/Rate.vue";
import Dropdown from "primevue/dropdown";
import Vue2DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { getProducts } from "../api/products.api";
import VueApexCharts from "vue-apexcharts";
import Button from "primevue/button";
import { ArrowDownRight, ArrowUpRight } from "../common/icons";
import {
  costPerDelivery,
  ordersRate,
  ordersTimeseries,
} from "../api/orders.api";
import { getPeriodString, notAfterToday } from "../common/utils";

const SHORTCUTS = [
  {
    text: "Today",
    onClick() {
      const start = moment().toDate();
      const end = moment().toDate();
      return [start, end];
    },
  },
  {
    text: "Last 24h",
    onClick() {
      const start = moment().subtract(1, "days").toDate();
      const end = moment().toDate();
      return [start, end];
    },
  },
  {
    text: "This week",
    onClick() {
      const start = moment().startOf("week").toDate();
      const end = moment().endOf("week").toDate();
      return [start, end];
    },
  },
  {
    text: "Last week",
    onClick() {
      const start = moment().subtract(1, "weeks").startOf("week").toDate();
      const end = moment().subtract(1, "weeks").endOf("week").toDate();
      return [start, end];
    },
  },
  {
    text: "This Month",
    onClick() {
      const start = moment().startOf("month").toDate();
      const end = moment().endOf("month").toDate();
      // return an array of two dates
      return [start, end];
    },
  },
  {
    text: "Last Month",
    onClick() {
      const start = moment().subtract(1, "months").startOf("month").toDate();
      const end = moment().subtract(1, "months").endOf("month").toDate();
      return [start, end];
    },
  },
  {
    text: "This Year",
    onClick() {
      const start = moment().startOf("year").toDate();
      const end = moment().endOf("year").toDate();
      return [start, end];
    },
  },
  {
    text: "Last Year",
    onClick() {
      const start = moment().subtract(1, "year").startOf("year").toDate();
      const end = moment().subtract(1, "year").endOf("year").toDate();
      return [start, end];
    },
  },
];

const DEFAULT_RATE_VALUES = {
  rate: 0,
  current: 0,
};

const DEFAULT_COST_PER_DELIVERY = {
  CPDTotal: 0,
  CPDAds: 0,
  CPDCharges: 0,
};

const OPTIONS = {
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    type: "datetime",
  },

  fill: {
    opacity: 1,
  },
};

export default {
  name: "DashboardV2",
  components: {
    Dropdown,
    Button,
    Vue2DatePicker,
    Rate,
    VueApexCharts,
    ArrowDownRight,
    ArrowUpRight,
  },
  props: {
    idWharhouse: { type: Object },
  },
  data: () => ({
    SHORTCUTS,
    OPTIONS,
    isLoading: false,
    period: "Today",
    products: [],
    productTags: [],
    selectedProduct: null,
    selectedProductTag: null,
    selectedDateRange: [moment().toDate(), moment().toDate()],
    costPerDeliveryDetails: {
      costs: DEFAULT_COST_PER_DELIVERY,
      series: [],
    },
    ordersRateDetails: {
      rates: {
        total: DEFAULT_RATE_VALUES,
        delivered: DEFAULT_RATE_VALUES,
        confirmed: DEFAULT_RATE_VALUES,
      },
      series: {
        total: [],
        delivered: [],
        confirmed: [],
      },
    },
    filterOptions: {
      tag: null,
      product: null,
      startDate: null,
      endDate: null,
      idWharhouse: null,
    },
  }),

  watch: {
    selectedProduct(product) {
      this.filterOptions.product = product?._id;
      if (!product) {
        this.productTags = [];
        this.selectedProductTag = null;
        return;
      }
      this.productTags = this.products
        .filter((product) => product._id === product?._id)[0]
        ?.tags.map((tag) => ({ label: tag, value: tag }));
    },
    selectedProductTag(productTag) {
      if (!productTag) {
        this.filterOptions.tag = null;
        return;
      }
      this.filterOptions.tag = productTag?.value;
    },
    selectedDateRange(dates) {
      const now = moment();

      const isSameDate = moment(dates[0]).isSame(dates[1], "day");
      if (!dates || (!dates[0] && !dates[1]) || isSameDate) {
        dates[0] = now.clone().utc().startOf("day").unix();
        dates[1] = now.clone().utc().endOf("day").unix();
      }

      this.filterOptions.startDate = dates[0]
        ? moment.utc(dates[0]).unix()
        : null;
      this.filterOptions.endDate = dates[1]
        ? moment.utc(dates[1]).unix()
        : null;

      this.period = getPeriodString({
        startDate: dates[0],
        endDate: dates[1],
      });
    },
    idWharhouse: async function (oldVal, newVal) {
      this.filterOptions.country = oldVal.country;
      this.fetchDataAnalytics(this.filterOptions);
      this.fetchProducts()

    },

    filterOptions: {
      handler() {
        this.fetchDataAnalytics(this.filterOptions);
      },
      deep: true,
    },

  },

  async mounted() {
    this.fetchProducts();
    this.filterOptions.country = this.idWharhouse.country

    await this.fetchDataAnalytics(this.filterOptions);


  },

  methods: {
    notAfterToday(date) {
      return moment(date).isAfter(moment());
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: 'USD',
      }).format(value || 0);
    },

    async fetchProducts() {
      const result = await getProducts({'details.warehouse' : this.idWharhouse._id});
      this.products = result.content.results;
    },

    async fetchDataAnalytics(params = {}) {
      this.isLoading = true;
      const [oCosts, oRates] = await Promise.all([
        this.fetchCostsDeliveryData(params),
        this.fetchOrdersRates(params),
      ]);
      this.costPerDeliveryDetails = oCosts;
      this.ordersRateDetails = oRates;
      this.isLoading = false;
    },

    async fetchCostsDeliveryData(params = {}) {
      const promises = [
        costPerDelivery(params),
        ordersTimeseries({
          type: "costs",
          ...params,
        }),
      ];
      const results = await Promise.all(promises);
      const [costPerDeliveryResult, ordersTimeseriesResult] = results;
      const { content } = costPerDeliveryResult;
      const { content: ordersTimeseriesContent } = ordersTimeseriesResult;
      return {
        costs: content.costs,
        series: ordersTimeseriesContent,
      };
    },

    async fetchOrdersRates(params) {
      const promises = [
        ordersRate({
          status: "Delivered,Confirmed",
          ...params,
        }),
        ordersTimeseries({
          status: "Confirmed",
          ...params,
        }),
        ordersTimeseries({
          status: "Delivered",
          ...params,
        }),
        ordersTimeseries(params),
      ];

      const results = await Promise.all(promises);

      const [
        rateResult,
        confirmedSeriesResult,
        deliveredSeriesResult,
        totalSeriesResult,
      ] = results;

      const { content } = rateResult;
      const { content: confirmedSeries } = confirmedSeriesResult;
      const { content: deliveredSeries } = deliveredSeriesResult;
      const { content: totalSeries } = totalSeriesResult;

      const ratesByStatus = content.data.reduce((acc, rate) => {
        acc[rate.name] = rate;
        return acc;
      }, {});

      return {
        rates: {
          total: ratesByStatus["Total"],
          confirmed: ratesByStatus["Confirmed"],
          delivered: ratesByStatus["Delivered"],
        },
        series: {
          confirmed: confirmedSeries,
          delivered: deliveredSeries,
          total: totalSeries,
        },
      };
    },
  },
  computed: {
    _chartOptions() {
      return {
        ...this.OPTIONS,
        yaxis: {
          labels: {
            formatter: (value) => this.formatCurrency(value, true),
          },
        },
        tooltip: {
          x: {
            format:
              this.costPerDeliverySeries[0]?.data?.length === 24
                ? "dd/MM/yy:HH:mm"
                : "MM/yy",
          },
        },
      };
    },
    costPerDeliverySeries() {
      const { series } = this.costPerDeliveryDetails;
      return [
        {
          name: "Ads",
          data: series.ads || [],
        },
        {
          name: "Charges",
          data: series.charges || [],
        },
        {
          name: "Total",
          data: series.total || [],
        },
      ];
    },
  },
};
</script>
