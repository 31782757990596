<template>
  <!-- Header -->
  <div class="relative pb-4 pt-10">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4">
            <card-stats
              statSubtitle="Total orders"
              :statTitle="TotalOrder"
              :user="currentUser"
              statArrow="up"
              filter="true"
              action="Orders"
              :timeserch="timeserchorders"
              :loading="loadingorders"
              :from="from_orders"
              :to="to_orders"
              statPercentColor="text-green-500"
              statIconName="local_grocery_store"
              statIconColor="bg-indigo-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
              @FindRecords="FindRecords"
            />
          </div>
          <div
            v-if="currentUser.type == 'Admin' || currentUser.type == 'CEO'"
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Total Sellers"
              :statTitle="TotalSeller"
              :user="currentUser"
              statArrow="down"
              statPercentColor="text-red-500"
              statIconName="people"
              statIconColor="bg-yellow-500"
            />
          </div>
          <div
            v-if="
              currentUser.type == 'Seller' ||
              currentUser.type == 'Admin' ||
              currentUser.type == 'CEO'
            "
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Total Products"
              :statTitle="TotalProduct"
              :user="currentUser"
              statArrow="down"
              statPercentColor="text-red-500"
              statIconName="local_mall"
              statIconColor="bg-orange-500"
            />
          </div>
          <!-- <div
            v-if="currentUser.type == 'Seller' || currentUser.type == 'Admin' || currentUser.type == 'CEO'"
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Total Sales"
              :statTitle="TotalOrdersPaid"
              statArrow="down"
              currency="true"
              action="Paid"
              :loading="loadingsales"
              filter="true"
              :timeserch="timeserchsales"
              :count="countOrdersPaid"
              :from="from_paid"
              :to="to_paid"
              countText="Orders"
              statPercentColor="text-red-500"
              statIconName="attach_money"
              statIconColor="bg-indigo-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
              @FindRecords="FindRecords"
            />
          </div> -->
          <!-- <div
            v-if="currentUser.type == 'Seller' || currentUser.type == 'Admin' || currentUser.type == 'CEO'"
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Total REVENUES"
              :statTitle="TotalOrdersNotProcessed"
              statArrow="down"
              currency="true"
              :loading="loadingrevenu"
              filter="true"
              action="Processed"
              :timeserch="timeserchrevenues"
              :from="from_revenues"
              :to="to_revenues"
              :count="countOrdersProcessed"
               countText="Orders"
              statPercentColor="text-red-500"
              statIconName="monetization_on"
              statIconColor="bg-yellow-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
              @FindRecords="FindRecords"
            />
          </div>
          <div
            v-if="currentUser.type == 'Seller' || currentUser.type == 'Admin' || currentUser.type == 'CEO'"
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Pending Sales"
              :statTitle="TotalOrdersPendingPaid"
              statArrow="down"
              currency="true"
              action="Paid"
              :timeserch="timeserchsales"
              :count="countOrdersPendingPaid"
               countText="Orders"
              statPercentColor="text-red-500"
              statIconName="attach_money"
              statIconColor="bg-indigo-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
            />
          </div>
           <div
            v-if="currentUser.type == 'Seller' || currentUser.type == 'Admin' || currentUser.type == 'CEO'"
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Pending REVENUES"
              :statTitle="TotalOrdersPendingProcessed"
              statArrow="down"
              currency="true"
              action="Processed"
              :timeserch="timeserchrevenues"
              :count="countOrdersPendingProcessed"
               countText="Orders"
              statPercentColor="text-red-500"
              statIconName="monetization_on"
              statIconColor="bg-yellow-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
            />
          </div> -->

          <div class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4">
            <card-stats
              statSubtitle="Confirmation"
              titleRate="Confirmation Rate:"
              :datas="Confirmation"
              :rate="ConfirmationRate"
              :user="currentUser"
              statArrow="up"
              filter="true"
              min_height="true"
              action="Confirmation"
              :timeserch="timeserchconfirm"
              :loading="loadingconfirm"
              :from="from_confirm"
              :to="to_confirm"
              statPercentColor="text-green-500"
              statIconName="timeline"
              statIconColor="bg-indigo-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
              @FindRecords="FindRecords"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4">
            <card-stats
              statSubtitle="Delivery"
              titleRate="Delivery Rate:"
              :user="currentUser"
              :datas="Delivery"
              :rate="DeliveryRate"
              min_height="true"
              statArrow="up"
              filter="true"
              action="Delivery"
              :timeserch="timeserchdelivry"
              :loading="loadingdelivery"
              :from="from_delivery"
              :to="to_delivery"
              statPercentColor="text-green-500"
              statIconName="timeline"
              statIconColor="bg-indigo-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
              @FindRecords="FindRecords"
            />
          </div>
          <div
            v-if="
              currentUser.type == 'Seller' ||
              currentUser.type == 'Admin' ||
              currentUser.type == 'CEO'
            "
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Accounting"
              :datasAcounting="Accounting"
              :user="currentUser"
              statArrow="up"
              filter="true"
              min_height="true"
              filterSeller="true"
              :timeserchseller="timeserchsellerAccounting"
              action="Accounting"
              :sellers="sellers"
              :timeserch="timeserchaccounting"
              :loading="loadingaccounting"
              :userType="currentUser.type"
              :from="from_accounting"
              :to="to_accounting"
              statPercentColor="text-red-500"
              statIconName="monetization_on"
              statIconColor="bg-yellow-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
              @FindRecords="FindRecords"
              @ChangeSeller="ChangeSellerAccounting"
            />
          </div>
          <div
            v-if="currentUser.type == 'Admin' || currentUser.type == 'CEO'"
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Fees speedliv"
              statSubtitle1="Fees Storeino"
              :statTitle="Revenue"
              :user="currentUser"
              currency="true"
              filter="true"
              filterSeller="true"
              :timeserch="timeserchfees"
              :timeserchseller="timeserchsellerFees"
              :from="from_fees"
              :userType="currentUser.type"
              action="Revenue"
              :to="to_fees"
              :sellers="sellers"
              statArrow="down"
              statPercentColor="text-red-500"
              statIconName="monetization_on"
              statIconColor="bg-orange-500"
              @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
              @FindRecords="FindRecords"
              :loading="loadingfees"
              @ChangeSeller="ChangeSellerFees"
            />
          </div>
          <div
            v-if="
              currentUser.type == 'Seller' ||
              currentUser.type == 'Admin' ||
              currentUser.type == 'CEO'
            "
            class="w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
          >
            <card-stats
              statSubtitle="Expedition fees paid"
              :statTitle="FeesExpeditions"
              :user="currentUser"
              :count="countExpeditions"
              countText="Expeditions"
              currency="true"
              statArrow="down"
              statPercentColor="text-red-500"
              statIconName="monetization_on"
              statIconColor="bg-orange-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import ChartDeliveryLiv from "@/components/Analytics/Cards/ChartLivraison.vue";
export default {
  components: {
    CardStats,
    ChartDeliveryLiv,
  },
  data() {
    return {
      currentUser: {
        role: {},
      },
      sellers: [],
      Confirmation: [],
      countshipp: 0,
      countdeliv: 0,
      countcancell: 0,
      ConfirmationRate: 0,
      Delivery: [],
      DeliveryRate: 0,
      Accounting: [],
      dataDelivery: [0, 0, 0],
      TotalOrder: "",
      FeesExpeditions: "",
      TotalSeller: "",
      TotalProduct: "",
      TotalOrdersPaid: "",
      TotalOrdersPendingPaid: "",
      TotalOrdersNotProcessed: "",
      TotalOrdersPendingProcessed: "",
      Revenue: "",
      countOrdersPaid: 0,
      countOrdersPendingPaid: 0,
      countOrdersProcessed: 0,
      countExpeditions: 0,
      countOrdersPendingProcessed: 0,
      timeserchdelivery: "thismonth",
      timeserchsales: "all",
      timeserchorders: "all",
      timeserchrevenues: "all",
      timeserchconfirm: "all",
      timeserchdelivry: "all",
      timeserchaccounting: "all",
      timeserchfees: "all",
      timeserchsellerAccounting: "All",
      timeserchsellerFees: "All",
      timeserchsellerDelivery: "All",
      from_sh: null,
      to_sh: null,
      from_orders: null,
      to_orders: null,
      from_paid: null,
      to_paid: null,
      from_revenues: null,
      to_revenues: null,
      from_confirm: null,
      to_confirm: null,
      from_delivery: null,
      to_delivery: null,
      from_accounting: null,
      to_accounting: null,
      from_fees: null,
      to_fees: null,
      loadingsales: false,
      loadingrevenu: false,
      loadingorders: false,
      loadingconfirm: false,
      loadingdelivery: false,
      loadingaccounting: false,
      loadingfees: false,
      idWharhouse: null,
      citiesPrices: [],
      chartOptions: {
        colors: ["#4299e1", "#48bb78", "#f56565"],
        noData: {
          text: "There's no data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
        },
        chart: {
          width: 380,
          type: "pie",
          id: "chart",
          toolbar: {
            show: false,
          },
        },
        labels: ["Shipped", "Delivered", "Cancelled"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  async mounted() {
    await this.getUser();
    await this.getwharhouseId();
    await this.getTotalOrdersPaid({
      Response: "Shippings",
      Seller: this.timeserchsellerDelivery,
      DateType: this.timeserchdelivery,
      country: await this.warhouseSelected,
    });
    if (this.currentUser.type && this.currentUser.type != "Seller")
      await this.getSellers();
    await this.getTotalSeller();
    await this.getTotalProduct();
    await this.getTotalOrdersPaid({
      Response: "Orders",
      DateType: this.timeserchorders,
      country: await this.warhouseSelected,
    });
    await this.getTotalOrdersPaid({
      Response: "Accounting",
      Seller: this.timeserchsellerAccounting,
      DateType: this.timeserchaccounting,
      country: await this.warhouseSelected,
    });
    await this.getTotalOrdersPaid({
      Response: "Confirmation",
      DateType: this.timeserchconfirm,
      country: await this.warhouseSelected,
    });
    await this.getTotalOrdersPaid({
      Response: "Delivery",
      DateType: this.timeserchdelivry,
      country: await this.warhouseSelected,
    });
    // await this.getTotalOrdersPaid({ Response: "Paid",DateType:this.timeserchsales });
    // await this.getTotalOrdersPaid({ Response: "PendingPaid" });
    // await this.getTotalOrdersPaid({ Response: "Processed",DateType:this.timeserchrevenues });
    //await this.getTotalOrdersPaid({ Response: "PendingProcessed" });
    await this.getTotalOrdersPaid({
      Response: "Revenue",
      Seller: this.timeserchsellerFees,
      DateType: this.timeserchfees,
      country: await this.warhouseSelected,
    });
    await this.getTotalFeesExpeditions();
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      this.timeserchsales =
        this.timeserchorders =
        this.timeserchconfirm =
        this.timeserchdelivry =
        this.timeserchaccounting =
        this.timeserchfees =
          "all";
      this.timeserchdelivery = "thismonth";
      await this.getwharhouseId();
      if (this.currentUser.type && this.currentUser.type != "Seller")
        await this.getSellers();
      await this.getTotalOrdersPaid({
        Response: "Shippings",
        Seller: this.timeserchsellerDelivery,
        DateType: this.timeserchdelivery,
        country: await this.warhouseSelected,
      });
      await this.getTotalOrdersPaid({
        Response: "Accounting",
        Seller: this.timeserchsellerAccounting,
        DateType: this.timeserchaccounting,
        country: await this.warhouseSelected,
      });
      await this.getTotalOrdersPaid({
        Response: "Confirmation",
        DateType: this.timeserchconfirm,
        country: await this.warhouseSelected,
      });
      await this.getTotalOrdersPaid({
        Response: "Delivery",
        DateType: this.timeserchdelivry,
        country: await this.warhouseSelected,
      });
      await this.getTotalOrdersPaid({
        Response: "Orders",
        DateType: this.timeserchorders,
        country: await this.warhouseSelected,
      });
      await this.getTotalSeller();
      await this.getTotalProduct();
      await this.getTotalOrdersPaid({
        Response: "Revenue",
        DateType: this.timeserchfees,
        Seller: this.timeserchsellerFees,
        country: await this.warhouseSelected,
      });
      await this.getTotalFeesExpeditions();
    },
  },
  methods: {
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async getCitiesPrices() {
      const resCities2 = await this.$server.get("zones", {
        allCitiesAndFees: true,
        country: await this.warhouseSelected,
      });
      if (resCities2.content.length > 0) {
        for (let i in resCities2.content) {
          for (let j in resCities2.content[i]._id.cities) {
            this.citiesPrices.push({
              name: resCities2.content[i]._id.cities[j],
              price: resCities2.content[i]._id.price,
            });
          }
        }
      } else this.citiesPrices = [];
    },
    async getSellers() {
      const res = await this.$server.search("users", {
        type: "Seller",
        countries: await this.warhouseSelected,
        limit: 4000,
      });
      if (
        res.content.results &&
        (this.currentUser.type == "Admin" || this.currentUser.type == "CEO")
      ) {
        this.sellers = res.content.results;
        this.sellers.unshift("All");
      }
    },
    async ChangeTypeDateDelivery(event) {
      this.timeserchdelivery = event.target.value;
      if (event.target.value != "betweendate") {
        this.dataDelivery = [0, 0, 0];
        await this.getTotalOrdersPaid({
          Response: "Shippings",
          Seller: this.timeserchsellerDelivery,
          DateType: this.timeserchdelivery,
          country: await this.warhouseSelected,
        });
      }
    },
    async FindRecordShippings(from, to) {
      this.from_sh = from;
      this.to_sh = to;

      await this.getTotalOrdersPaid({
        DateType: "betweendate",
        Response: "Shippings",
        dates: { from: from, to: to },
        Seller: this.timeserchsellerDelivery,
        country: await this.warhouseSelected,
      });
    },
    async ChangeSellerAccounting(seller) {
      this.timeserchsellerAccounting = seller;
      await this.getTotalOrdersPaid({
        Response: "Accounting",
        Seller: this.timeserchsellerAccounting,
        DateType: this.timeserchaccounting,
        country: await this.warhouseSelected,
      });
    },
    async ChangeSellerFees(seller) {
      this.timeserchsellerFees = seller._id;
      await this.getTotalOrdersPaid({
        Response: "Revenue",
        Seller: this.timeserchsellerFees,
        DateType: this.timeserchfees,
        country: await this.warhouseSelected,
      });
    },
    async ChangeSellerDelivery(seller) {
      this.timeserchsellerDelivery = seller;
      await this.getTotalOrdersPaid({
        Response: "Shippings",
        Seller: this.timeserchsellerDelivery,
        DateType: this.timeserchdelivery,
        country: await this.warhouseSelected,
      });
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    async ChangeTypeDateRevenu(event) {
      if (event.target.name == "Paid") {
        this.timeserchsales = event.target.value;
      }
      if (event.target.name == "Orders") {
        this.timeserchorders = event.target.value;
      }
      if (event.target.name == "Processed") {
        this.timeserchrevenues = event.target.value;
      }
      if (event.target.name == "Confirmation") {
        this.timeserchconfirm = event.target.value;
      }
      if (event.target.name == "Delivery") {
        this.timeserchdelivry = event.target.value;
      }
      if (event.target.name == "Accounting") {
        this.timeserchaccounting = event.target.value;
      }
      if (event.target.name == "Revenue") {
        this.timeserchfees = event.target.value;
      }

      if (event.target.value == "all") {
        await this.getTotalOrdersPaid({
          DateType: event.target.value,
          Response: event.target.name,
          country: await this.warhouseSelected,
        });
      }
    },
    async FindRecords(action, from, to) {
      if (action == "Paid") {
        this.from_paid = from;
        this.to_paid = to;
      }
      if (action == "Orders") {
        this.from_orders = from;
        this.to_orders = to;
      }
      if (action == "Processed") {
        this.from_revenues = from;
        this.to_confirm = to;
      }
      if (action == "Confirmation") {
        this.from_confirm = from;
        this.to_revenues = to;
      }
      if (action == "Delivery") {
        this.from_delivery = from;
        this.to_delivery = to;
      }
      if (action == "Accounting") {
        this.from_accounting = from;
        this.to_accounting = to;
      }
      if (action == "Revenue") {
        this.from_fees = from;
        this.to_fees = to;
      }

      await this.getTotalOrdersPaid({
        DateType: "betweendate",
        dates: { from: from, to: to },
        Response: action,
        country: await this.warhouseSelected,
      });
    },
    async getTotalOrders() {
      const res = await this.$server.getTotalOrders("analytics");
      this.TotalOrder = res.content;
    },
    async getTotalFeesExpeditions() {
      const res = await this.$server.getTotalFeesExpeditions("analytics", {
        country: await this.warhouseSelected,
      });
      this.FeesExpeditions = res.content.sum;
      this.countExpeditions = res.content.count;
    },
    async getTotalSeller() {
      const res = await this.$server.getTotalSeller("analytics", {
        country: await this.warhouseSelected,
      });
      this.TotalSeller = res.content;
    },

    async getTotalProduct() {
      const res = await this.$server.getTotalProduct("analytics", {
        warehouse: await this.idWharhouse._id,
      });
      this.TotalProduct = res.content;
    },

    async getTotalOrdersPaid(filters) {
      let res;
      switch (filters.Response) {
        case "Paid":
          this.loadingsales = true;
          res = await this.$server.getTotalOrdersPaid("analytics", filters);
          this.loadingsales = false;
          this.TotalOrdersPaid = res.content.sum;
          this.countOrdersPaid = res.content.count;
          break;
        case "PendingPaid":
          res = await this.$server.getTotalOrdersPaid("analytics", filters);
          this.TotalOrdersPendingPaid = res.content.sum;
          this.countOrdersPendingPaid = res.content.count;
          break;
        case "Processed":
          this.loadingrevenu = true;
          res = await this.$server.getTotalOrdersPaid("analytics", filters);
          this.TotalOrdersNotProcessed = res.content.sum;
          this.countOrdersProcessed = res.content.count;
          this.loadingrevenu = false;
          break;
        case "PendingProcessed":
          res = await this.$server.getTotalOrdersPaid("analytics", filters);
          this.TotalOrdersPendingProcessed = res.content.sum;
          this.countOrdersPendingProcessed = res.content.count;
          break;
        case "Revenue":
          this.loadingfees = true;
          res = await this.$server.getTotalOrdersPaid("analytics", filters);
          this.Revenue = res.content.sum;
          this.loadingfees = false;
          break;
        case "Orders":
          this.loadingorders = true;
          res = await this.$server.getTotalOrdersPaid("analytics", filters);
          this.TotalOrder = res.content.count;
          this.loadingorders = false;
          break;
        case "Confirmation":
          this.loadingconfirm = true;
          res = await this.$server.getTotalOrdersPaid("analytics", filters);
          this.Confirmation = res.content.datas;
          this.ConfirmationRate = res.content.rate;
          this.loadingconfirm = false;
          break;
        case "Delivery":
          this.loadingdelivery = true;
          res = await this.$server.getTotalOrdersPaid("analytics", filters);

          this.Delivery = res.content.datas;
          this.DeliveryRate = res.content.rate;
          this.loadingdelivery = false;
          break;
        case "Shippings":
          if (filters.Seller != "All") filters.Seller = filters.Seller._id;
          res = await this.$server.getTotalOrdersPaid("analytics", filters);

          this.dataDelivery = res.content.datas;
          this.countshipp = res.content.datas[0];
          this.countdeliv = res.content.datas[1];
          this.countcancell = res.content.datas[2];
          break;
        case "Accounting":
          this.loadingaccounting = true;
          filters.user = this.currentUser.type;
          filters.warhouse = this.idWharhouse._id;
          filters.warhouseCountry = await this.warhouseSelected;

          res = await this.$server.getTotalOrdersPaid("analytics", filters);

          this.Accounting = res.content.datas;
          this.loadingaccounting = false;
          break;
        default:
      }
    },
  },
};
</script>
