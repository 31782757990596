<template>
  <div
    class="card relative radius-10"
    :class="
      currentUser && currentUser.type && currentUser.type == 'Seller'
        ? 'min-w-seller'
        : ''
    "
  >
    <loading :active.sync="loading" :is-full-page="fullPage"></loading>
    <div class="card-body flex flex-col justify-between">
      <div
        v-if="
          filterseller &&
          currentUser &&
          currentUser.type &&
          currentUser.role &&
          currentUser.role.name
        "
        class="relative filter-dash justify-start"
      >
        <div class="flex p-1 justify-between">
          <div
            :class="
              action == 'Products' ||
              action === 'Revenues' ||
              action === 'Processed'
                ? 'ww-40'
                : 'ww-3/7'
            "
            v-if="features['multi-sellers'].isActive === true"
          >
            <div class="flex">
              <div
                class="w-10 z-1 pl-1 text-center pointer-events-none flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-gray-600 fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"
                  />
                </svg>
              </div>
              <v-select
                @search="getUsersDebance($event, 'ouaka')"
                @input="CheckSellerProducts($event, action, 'seller')"
                label="fullName"
                :placeholder="$t('seller')"
                :options="sellers"
                v-model="sellerdata"
                class="text-gray-600 bg-white w-full -ml-10 pl-8 pr-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
              ></v-select>
            </div>
          </div>
          <div
            v-if="
              action &&
              action != 'Products' &&
              action != 'Revenues' &&
              action != 'Processed'
            "
            class="px-1"
            :class="
              currentUser.role.name == 'Seller' && currentUser.type == 'Seller'
                ? 'w-11/12'
                : 'ww-3/7'
            "
          >
            <div class="flex">
              <div
                class="w-10 z-1 pl-1 text-center pointer-events-none flex items-center justify-center"
              >
                <svg
                  class="text-gray-600 fill-current"
                  width="15"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M11.5 23l-8.5-4.535v-3.953l5.4 3.122 3.1-3.406v8.772zm1-.001v-8.806l3.162 3.343 5.338-2.958v3.887l-8.5 4.534zm-10.339-10.125l-2.161-1.244 3-3.302-3-2.823 8.718-4.505 3.215 2.385 3.325-2.385 8.742 4.561-2.995 2.771 2.995 3.443-2.242 1.241v-.001l-5.903 3.27-3.348-3.541 7.416-3.962-7.922-4.372-7.923 4.372 7.422 3.937v.024l-3.297 3.622-5.203-3.008-.16-.092-.679-.393v.002z"
                  />
                </svg>
              </div>
              <v-select
                @search="getProducts($event, 'ouaka')"
                @input="CheckSellerProducts($event, action, 'product')"
                label="name"
                :placeholder="$t('Product name')"
                :options="products"
                v-model="productdata"
                class="text-gray-600 bg-white w-full -ml-10 pl-8 pr-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
              ></v-select>
            </div>
          </div>
          <div
            :class="
              action == 'Products' ||
              action === 'Revenues' ||
              action === 'Processed'
                ? currentUser.role.name != 'Seller' &&
                  currentUser.type != 'Seller'
                  ? 'pl-1 ww-40'
                  : 'ww-90 px-1'
                : 'ww-3/7'
            "
            v-if="filter && action != 'Expeditions'"
            :style="{
              flex: !features['multi-sellers'].isActive && 1,
            }"
          >
            <div
              class="rounded-lg border-2 border-gray-200 hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
            >
              <p>
                <select
                  id="type"
                  :name="action"
                  autocomplete="type"
                  @change="ChangeDatevalue($event)"
                  v-model="timeserchtype"
                  class="py-2 text-xs text-gray-600 capitalize px-2 cursor-pointer outline-none block h-full w-full"
                >
                  <option class="bg-white text-gray-600" value="all">
                    All
                  </option>
                  <option class="bg-white text-gray-600" value="today">
                    Today
                  </option>
                  <option class="bg-white text-gray-600" value="yesterday">
                    Yesterday
                  </option>
                  <option class="bg-white text-gray-600" value="thisweek">
                    This week
                  </option>
                  <option class="bg-white text-gray-600" value="lastweek">
                    Last week
                  </option>
                  <option class="bg-white text-gray-600" value="thismonth">
                    This Month
                  </option>
                  <option class="bg-white text-gray-600" value="lastmonth">
                    Last Month
                  </option>
                  <option class="bg-white text-gray-600" value="thisyear">
                    This Year
                  </option>
                  <option class="bg-white text-gray-600" value="betweendate">
                    Between two date
                  </option>
                </select>
              </p>
            </div>
          </div>
          <div class="ww-1/7 flex justify-center items-center">
            <svg
              v-if="
                currentUser.role.name == 'Seller' &&
                currentUser.type == 'Seller' &&
                (action === 'Revenues' ||
                  action === 'Processed' ||
                  action === 'Products')
              "
              @click="removedata(action)"
              xmlns="http://www.w3.org/2000/svg"
              class="text-gray-600 fill-current cursor-pointer"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                d="M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z"
              />
            </svg>
            <svg
              v-else
              @click="removedata(action)"
              xmlns="http://www.w3.org/2000/svg"
              class="text-gray-600 fill-current cursor-pointer"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"
              />
            </svg>
          </div>
        </div>
        <div v-if="filter && timeserchtype == 'betweendate'" class="p-1">
          <!-- <div class="inline-block mt-2 ww-45 pr-1">
                        <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
                            <p>  <input :name="action" v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                        </div>
                    </div>
                    <div class="inline-block mt-2 ww-45 pr-1">
                        <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
                            <p>  <input :name="action" v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                        </div>
                    </div> -->
          <div class="flex items-center space-x-2 sm:space-x-2">
            <date-picker
              placeholder="Range date ..."
              format="YYYY-MM-DD"
              type="date"
              class="w-full-imp shadow"
              v-model="fromDate"
              range
            ></date-picker>
            <!-- <button @click="FindRecordsBetweenDate()" :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button> -->
            <svg
              @click="FindRecordsBetweenDate()"
              :class="!fromDate ? 'btn-disable' : ''"
              :disabled="!fromDate"
              class="text-gray-600 fill-current cursor-pointer"
              width="22"
              height="22"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
            >
              <path
                d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7zm-3-8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
              />
            </svg>
          </div>
        </div>
      </div>
      <hr
        v-if="
          filterseller &&
          currentUser &&
          currentUser.type &&
          currentUser.role &&
          currentUser.role.name
        "
      />
      <div class="d-flex align-items-center">
        <div>
          <p class="mb-0 text-gray-500 uppercase font-bold text-xs">
            {{ $t(statSubtitle) }}
            <span
              style="font-size: 12px"
              v-if="
                currentUser &&
                currentUser.role &&
                currentUser.role.name &&
                action &&
                action == 'Revenues' &&
                currentUser.role.name != 'Seller' &&
                currentUser.type != 'Seller'
              "
              >({{ datas.count + " " + $t("orders") }})</span
            >
          </p>
          <div
            v-if="
              currentUser &&
              currentUser.type &&
              currentUser.type != 'Seller' &&
              action &&
              action === 'Revenues'
            "
            class="flex mt-1"
          >
            <div>
              <div class="text-primary dark:text-slate-300 text-sm font-bold">
                <sup v-if="currency">{{ currency }}</sup> {{ datas.sum }}
              </div>
              <div class="mt-0.5 text-gray-700 fs-12">REVENUES</div>
            </div>
            <div
              class="w-px h-12 border border-r border-dashed border-gray-600 dark:border-darkmode-300 mx-4 xl:mx-5"
            ></div>
            <div>
              <div class="text-primary dark:text-slate-300 text-sm font-bold">
                <sup v-if="currency">{{ currency }}</sup> {{ datas.sumFees }}
              </div>
              <div class="mt-0.5 text-gray-700 fs-12">
                {{ $t("FEES codservice") }}
              </div>
            </div>
            <div
              class="w-px h-12 border border-r border-dashed border-gray-600 dark:border-darkmode-300 mx-4 xl:mx-5"
            ></div>
            <div>
              <svg
                v-if="!open"
                @click="show"
                class="fill-current cursor-pointer mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"
                />
              </svg>
              <svg
                v-if="open"
                @click="show"
                class="fill-current cursor-pointer mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"
                />
              </svg>
              <div
                v-if="open"
                class="text-slate-500 text-sm font-bold inline-block"
              >
                <sup v-if="currency">{{ currency }}</sup>
                {{ ((5 * datas.sumFees) / 100).toFixed(2) }}
              </div>
              <div v-if="open" class="mt-0.5 fs-12 text-gray-700">
                FEES STOREINO (-5%)
              </div>
            </div>
          </div>
          <div v-else-if="action === 'Sellers'" class="flex my-2">
            <span
              v-if="datas.dataStatus && datas.dataStatus[0]"
              class="inline-flex"
            >
              <div>
                <div class="text-primary dark:text-slate-300 text-sm font-bold">
                  {{ datas.dataStatus[0].count }}
                </div>
                <div class="mt-0.5 text-gray-700 fs-12 uppercase">
                  {{ datas.dataStatus[0]._id.status }}
                </div>
              </div>
              <div
                v-if="datas.dataStatus.length > 1"
                class="w-px h-12 border border-r border-dashed border-gray-600 dark:border-darkmode-300 mx-4 xl:mx-5"
              ></div>
            </span>
            <span
              v-if="datas.dataStatus && datas.dataStatus[1]"
              class="inline-flex"
            >
              <div>
                <div class="text-primary dark:text-slate-300 text-sm font-bold">
                  {{ datas.dataStatus[1].count }}
                </div>
                <div class="mt-0.5 text-gray-700 fs-12 uppercase">
                  {{ datas.dataStatus[1]._id.status }}
                </div>
              </div>
              <div
                v-if="datas.dataStatus.length > 2"
                class="w-px h-12 border border-r border-dashed border-gray-600 dark:border-darkmode-300 mx-4 xl:mx-5"
              ></div>
            </span>
            <span
              v-if="datas.dataStatus && datas.dataStatus[2]"
              class="inline-flex"
            >
              <div>
                <div class="text-primary dark:text-slate-300 text-sm font-bold">
                  {{ datas.dataStatus[2].count }}
                </div>
                <div class="mt-0.5 text-gray-700 fs-12 uppercase">
                  {{ datas.dataStatus[2]._id.status }}
                </div>
              </div>
              <div
                v-if="datas.dataStatus.length > 3"
                class="w-px h-12 border border-r border-dashed border-gray-600 dark:border-darkmode-300 mx-4 xl:mx-5"
              ></div>
            </span>
            <span
              v-if="datas.dataStatus && datas.dataStatus[3]"
              class="inline-flex"
            >
              <div>
                <div class="text-primary dark:text-slate-300 text-sm font-bold">
                  {{ datas.dataStatus[3].count }}
                </div>
                <div class="mt-0.5 text-gray-700 fs-12 uppercase">
                  {{ datas.dataStatus[3]._id.status }}
                </div>
              </div>
            </span>
          </div>
          <h4 v-else class="my-1 font-bold">
            <sup v-if="currency">{{ currency }}</sup>
            {{ datas.sum || datas.count }}
            <span
              style="font-size: 12px"
              v-if="
                action == 'Revenues' ||
                action == 'RevenuesPay' ||
                action == 'Processed'
              "
              >({{ datas.count + " " + $t("orders") }})</span
            >
          </h4>
          <p
            class="mb-0 font-13"
            :class="
              Math.sign(datas.since) === 1 ? 'text-success' : 'text-warnred'
            "
          >
            <svg
              v-if="Math.sign(datas.since) === 1"
              class="fill-current transform-1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 3l12 18h-24z" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="fill-current transform-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 21l-12-18h24z" />
            </svg>
            {{ Math.sign(datas.since) === 1 ? "+" : "" }}{{ datas.since || 0 }}%
            {{ $t("Since last") }} {{ $t(datas.field) }}
          </p>
        </div>
        <div
          :class="'bg-light-' + cl + ' text-' + cl"
          class="widgets-icons ms-auto"
        >
          <svg
            v-if="
              action == 'Revenues' ||
              action == 'RevenuesPay' ||
              action == 'Processed'
            "
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 9c5.345 0 10-2.015 10-4.5s-4.655-4.5-10-4.5c-5.344 0-10 2.015-10 4.5s4.656 4.5 10 4.5zm.187-4.019c-.598-.18-2.428-.332-2.428-1.35 0-.568.668-1.074 1.917-1.187v-.444h.642v.422c.468.011.989.062 1.569.18l-.234.685c-.441-.101-.933-.199-1.416-.199l-.145.002c-.962.04-1.041.574-.373.799 1.092.337 2.532.585 2.532 1.479 0 .714-.867 1.097-1.934 1.192v.44h-.642v-.416c-.659-.006-1.353-.113-1.925-.304l.295-.686c.488.125 1.102.253 1.655.253.145 0 .284-.009.417-.028.737-.106.884-.602.07-.838zm-.187 16.019c3.783 0 7.708-.969 10-2.803v1.303c0 2.485-4.655 4.5-10 4.5-5.344 0-10-2.015-10-4.5v-1.304c2.292 1.835 6.217 2.804 10 2.804zm0-10c3.783 0 7.708-.969 10-2.803v1.303c0 2.485-4.655 4.5-10 4.5-5.344 0-10-2.015-10-4.5v-1.303c2.292 1.834 6.217 2.803 10 2.803zm0 5c3.783 0 7.708-.969 10-2.803v1.303c0 2.485-4.655 4.5-10 4.5-5.344 0-10-2.015-10-4.5v-1.304c2.292 1.835 6.217 2.804 10 2.804z"
            />
          </svg>
          <svg
            v-else-if="action == 'Orders'"
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M21.698 10.658l2.302 1.342-12.002 7-11.998-7 2.301-1.342 9.697 5.658 9.7-5.658zm-9.7 10.657l-9.697-5.658-2.301 1.343 11.998 7 12.002-7-2.302-1.342-9.7 5.657zm0-19l8.032 4.685-8.032 4.685-8.029-4.685 8.029-4.685zm0-2.315l-11.998 7 11.998 7 12.002-7-12.002-7z"
            />
          </svg>
          <svg
            v-else-if="action == 'Products'"
            class="fill-current"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path
              d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"
            />
          </svg>
          <svg
            v-else-if="action == 'Sellers'"
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"
            />
          </svg>
          <svg
            v-else
            class="fill-current"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path
              d="M2.978 8.358l-2.978-2.618 8.707-4.74 3.341 2.345 3.21-2.345 8.742 4.639-3.014 2.68.014.008 3 4.115-3 1.634v4.122l-9 4.802-9-4.802v-4.115l1 .544v2.971l7.501 4.002v-7.889l-2.501 3.634-9-4.893 2.978-4.094zm9.523 5.366v7.875l7.499-4.001v-2.977l-5 2.724-2.499-3.621zm-11.022-1.606l7.208 3.918 1.847-2.684-7.231-3.742-1.824 2.508zm11.989 1.247l1.844 2.671 7.208-3.927-1.822-2.498-7.23 3.754zm-9.477-4.525l8.01-4.43 7.999 4.437-7.971 4.153-8.038-4.16zm-2.256-2.906l2.106 1.851 7.16-3.953-2.361-1.657-6.905 3.759zm11.273-2.052l7.076 3.901 2.176-1.935-6.918-3.671-2.334 1.705z"
            />
          </svg>
        </div>
      </div>
      <div :id="action" style="min-height: 55px"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "card-v1",
  props: {
    statSubtitle: { default: "Traffic" },
    loading: { required: false },
    timeserch: {},
    from: {},
    to: {},
    filter: { default: false },
    filterseller: { default: false },
    id: { type: String },
    color: { type: String },
    cl: { default: "success" },
    action: { type: String },
    currency: { default: false },
    count: { type: Number, default: 0 },
    datas: { type: Object },
    dataChart: { type: Array },
    currentUser: { type: Object },
    warhouse: { type: Object },
    sellers: { type: Array },
    products: { type: Array },
    seller: {},
    product: {},
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
  },
  data() {
    return {
      fullPage: false,
      open: false,
      timeserchtype: this.timeserch,
      myChart: "",
      fromDate: this.from,
      toDate: this.to,
      sellerdata: this.seller,
      productdata: this.product,
    };
  },
  computed: {
    ...mapState({
      features: (state) => state.features.all,
      mainSeller: (state) => state.app.defaultSeller,
    }),
  },
  watch: {
    dataChart: function () {
      if (this.myChart != null) this.myChart.destroy();
      this.drawChart(this.dataChart);
      this.myChart.render();
    },
    seller: function () {
      this.sellerdata = this.seller;
    },
    product: function () {
      this.productdata = this.product;
    },
  },
  async mounted() {
    await this.drawChart(this.dataChart);
    this.myChart.render();
    if (
      this.currentUser &&
      this.currentUser.role &&
      this.currentUser.role.name == "Seller" &&
      this.currentUser.type == "Seller"
    )
      this.sellerdata = this.currentUser;
  },
  methods: {
    show() {
      this.open = !this.open;
    },
    getPercentege(countStatus, total) {
      return total ? Math.round((countStatus * 100) / total) : 0;
    },
    FindRecordsBetweenDate: function (val) {
      this.$emit(
        "FindRecordsBetweenDate",
        this.action,
        this.fromDate.map((el) => this.$moment(el).format("yyyy-MM-DD")),
        this.toDate
      );
    },
    getUsersDebance: function (name) {
      this.$emit("getUsersDebance", name);
    },
    getProducts: function (name) {
      this.$emit("getProducts", name);
    },
    async CheckSellerProducts(seller, action, model) {
      this.$emit("CheckSellerProducts", seller, action, model);
    },
    removedata: function (val) {
      this.$emit("removedata", this.action);
    },
    drawChart(chartData) {
      var ctx = document.getElementById(this.action);
      this.myChart = new ApexCharts(ctx, {
        series: [
          {
            name: this.statSubtitle,
            data: chartData,
          },
        ],
        chart: {
          type: this.action && this.action == "Orders" ? "bar" : "line",
          height: 55,
          toolbar: {
            show: !1,
          },
          zoom: {
            enabled: !1,
          },
          dropShadow: {
            enabled: !0,
            top: 3,
            left: 14,
            blur: 4,
            opacity: 0.12,
            color: this.color,
          },
          sparkline: {
            enabled: !0,
          },
        },
        markers: {
          size: 0,
          colors: [this.color],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        dataLabels: {
          enabled: !1,
        },
        stroke: {
          show: !0,
          width: 3,
          curve: "smooth",
        },
        colors: [this.color],
        // xaxis: {
        //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        // },
        fill: {
          opacity: 1,
        },
        tooltip: {
          theme: "dark",
          fixed: {
            enabled: !1,
          },
          x: {
            show: !1,
          },
          y: {
            title: {
              formatter: function (e) {
                return "";
              },
            },
          },
          marker: {
            show: !1,
          },
        },
      });
    },
    ChangeDatevalue: function (val) {
      // if(val.target.name=="ConfirmationRate")  this.$emit("ChangeDateConfirmationRate", val);
      // if(val.target.name=="Delivery") this.$emit("ChangeDateConfirmationDelevry", val);
      this.$emit("ChangeDatevalue", val);
    },
    Refrechdata: function (val) {
      this.$emit("Refrechdata", val);
    },
    //  FindRecords: function () {
    //   this.$emit("FindRecords", this.action,this.fromDate,this.toDate);
    // },
  },
};
</script>
<style scoped>
.bg-light-success {
  background-color: #17a00e1c !important;
}
.fs-12 {
  font-size: 12px;
}
.bg-light-warning {
  background-color: #d79e0c1c !important;
}
.text-warning {
  color: #e5ab13 !important;
}
.bg-light-blue {
  background-color: #106efd21 !important;
}
.text-blue {
  color: #106efd !important;
}
.bg-light-ceil {
  background-color: #160fe140 !important;
}
.text-ceil {
  color: #160fe1 !important;
}
.bg-light-exp {
  background-color: #ff90024d !important;
}
.text-exp {
  color: #ee8b0c !important;
}

.bg-light-marin {
  background-color: #bf1bb921 !important;
}
.text-marin {
  color: #bf1bb9 !important;
}
.text-warnred {
  color: #ca1556;
}
.ww-3\/7 {
  width: 31%;
}
.ww-1\/7 {
  width: 7%;
}
.ww-40 {
  width: 48%;
}
.ww-45 {
  width: 47%;
}
.ww-90 {
  width: 95%;
}
.ww-10 {
  width: 10%;
}
.ww-3 {
  width: 6%;
}
.filter-dash {
  background-color: #f8f9fa;
  transition: all 0.2s;
  border: 1px solid #dee2e6 !important;
}
.z-1 {
  z-index: 1;
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}
.transform-1 {
  transform: scale(0.6);
}
.radius-10 {
  border-radius: 10px;
}
hr:not([size]) {
  height: 1px;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px #dadafda6, 0 2px 6px #ceceee8a;
  min-height: 268px;
}
.items-center {
  align-items: center !important;
}
.d-flex {
  display: flex !important;
}
.text-secondary {
  color: #6c757d !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.font-13 {
  font-size: 13px;
}
.text-success {
  color: #15ca20 !important;
}
.align-middle {
  vertical-align: middle !important;
}
svg {
  display: inline-block;
}
.bg-light-success {
  background-color: #17a00e1c !important;
}
.widgets-icons {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  font-size: 26px;
  border-radius: 10px;
}
.ms-auto {
  margin-left: auto !important;
}
.min-w-seller {
  min-height: 256px;
}
</style>
