var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.currentUser && _vm.currentUser.type && _vm.currentUser.type && _vm.currentUser.role && _vm.getPermission('dashboard') ? _c('div', [_c('Dashboard', {
    attrs: {
      "idWharhouse": _vm.idWharhouse
    },
    scopedSlots: _vm._u([{
      key: "left",
      fn: function fn() {
        return [_c('div', {
          staticClass: "px-4"
        }, [_c('card-v3', {
          attrs: {
            "statSubtitle": _vm.$t('Top products'),
            "statSubtitle1": _vm.$t('Top products by orders'),
            "filter": "true",
            "filterseller": "true",
            "action": "TopProducts",
            "timeserch": _vm.timeserchtopproducts,
            "loading": _vm.loadingtopproducts,
            "color": "#ee8b0c",
            "cl": "exp",
            "datas": _vm.dataTopProducts,
            "dataChart": _vm.dataTopProducts.data,
            "sellers": _vm.sellers,
            "seller": _vm.features['multi-sellers'].isActive ? _vm.seller_topproducts : _vm.mainSeller,
            "from": _vm.from_topproductss,
            "to": _vm.to_topproducts,
            "currentUser": _vm.currentUser
          },
          on: {
            "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
            "FindRecordsBetweenDate": _vm.FindRecordsHeader,
            "getUsersDebance": _vm.getUsersDebance,
            "CheckSellerProducts": _vm.CheckSellerProducts,
            "removedata": _vm.removedata
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3830389274)
  }), _c('div', {
    staticClass: "flex flex-wrap mt-5 px-6"
  }, [_vm.currentUser && _vm.currentUser.type && (_vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'Seller') ? _c('div', {
    staticClass: "w-full lg:w-1/2 md:w-1/2 xl:w-1/3 px-4"
  }, [_c('card-v1', {
    attrs: {
      "statSubtitle": "Total Revenues",
      "filter": "true",
      "filterseller": "true",
      "action": "Revenues",
      "loading": _vm.loadingrevenues,
      "color": "#17a00e",
      "cl": "success",
      "currency": _vm.idWharhouse ? _vm.idWharhouse.currency : '',
      "datas": _vm.dataRevenues,
      "dataChart": _vm.dataRevenues.data,
      "sellers": _vm.sellers,
      "timeserch": _vm.timeserchrevenues,
      "seller": _vm.features['multi-sellers'].isActive ? _vm.seller_revenues : _vm.mainSeller,
      "from": _vm.from_revenues,
      "to": _vm.to_revenues,
      "currentUser": _vm.currentUser
    },
    on: {
      "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
      "FindRecordsBetweenDate": _vm.FindRecordsHeader,
      "getUsersDebance": _vm.getUsersDebance,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1) : _vm._e(), _vm.currentUser && _vm.currentUser.type && (_vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'Seller') ? _c('div', {
    staticClass: "w-full lg:w-1/2 md:w-1/2 xl:w-1/3 px-4"
  }, [_c('card-v1', {
    attrs: {
      "statSubtitle": _vm.$t('Revenues to pay'),
      "filter": "true",
      "filterseller": "true",
      "action": "RevenuesPay",
      "loading": _vm.loadingrevenuespay,
      "color": "#ffc10d",
      "cl": "warning",
      "currency": _vm.idWharhouse ? _vm.idWharhouse.currency : '',
      "datas": _vm.dataRevenuespay,
      "dataChart": _vm.dataRevenuespay.data,
      "sellers": _vm.sellers,
      "products": _vm.products,
      "timeserch": _vm.timeserchrevenuespay,
      "seller": _vm.seller_revenuespay,
      "product": _vm.product_revenuespay,
      "from": _vm.from_revenuespay,
      "to": _vm.to_revenuespay,
      "currentUser": _vm.currentUser
    },
    on: {
      "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
      "FindRecordsBetweenDate": _vm.FindRecordsHeader,
      "getUsersDebance": _vm.getUsersDebance,
      "getProducts": _vm.getProducts,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1) : _vm._e(), _vm.currentUser && _vm.currentUser && _vm.currentUser.type && (_vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'Seller') ? _c('div', {
    staticClass: "w-full lg:w-1/2 md:w-1/2 xl:w-1/3 px-4"
  }, [_c('card-v1', {
    attrs: {
      "statSubtitle": _vm.$t('Total Processed'),
      "filter": "true",
      "filterseller": "true",
      "action": "Processed",
      "loading": _vm.loadingprocessed,
      "color": "#0d6efd",
      "cl": "blue",
      "currency": _vm.idWharhouse ? _vm.idWharhouse.currency : '',
      "datas": _vm.dataprocessed,
      "dataChart": _vm.dataprocessed.data,
      "sellers": _vm.sellers,
      "timeserch": _vm.timeserchprocessed,
      "seller": _vm.features['multi-sellers'].isActive ? _vm.seller_processed : _vm.mainSeller,
      "from": _vm.from_processed,
      "to": _vm.to_processed,
      "currentUser": _vm.currentUser
    },
    on: {
      "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
      "FindRecordsBetweenDate": _vm.FindRecordsHeader,
      "getUsersDebance": _vm.getUsersDebance,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "w-full lg:w-1/2 md:w-1/2 px-4",
    class: _vm.currentUser && _vm.currentUser.type != 'CEO' && _vm.currentUser.type != 'Seller' ? 'xl:w-1/2' : 'xl:w-1/3'
  }, [_c('card-v1', {
    attrs: {
      "statSubtitle": "Total Orders",
      "filter": "true",
      "filterseller": "true",
      "action": "Orders",
      "loading": _vm.loadingorders,
      "color": "#bf1bb9",
      "cl": "marin",
      "datas": _vm.datatotalorders,
      "dataChart": _vm.datatotalorders.data,
      "sellers": _vm.sellers,
      "products": _vm.products,
      "timeserch": _vm.timeserchorders,
      "from": _vm.from_orders,
      "to": _vm.to_orders,
      "currentUser": _vm.currentUser
    },
    on: {
      "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
      "FindRecordsBetweenDate": _vm.FindRecordsHeader,
      "getUsersDebance": _vm.getUsersDebance,
      "getProducts": _vm.getProducts,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1), _c('div', {
    staticClass: "w-full lg:w-1/2 md:w-1/2 px-4",
    class: _vm.currentUser.type != 'CEO' && _vm.currentUser.type != 'Seller' ? 'xl:w-1/2' : 'xl:w-1/3'
  }, [_c('card-v1', {
    attrs: {
      "statSubtitle": "Total Products",
      "filter": "true",
      "filterseller": "true",
      "action": "Products",
      "loading": _vm.loadingproducts,
      "color": "#160fe1",
      "cl": "ceil",
      "datas": _vm.dataproducts,
      "dataChart": _vm.dataproducts.data,
      "sellers": _vm.sellers,
      "timeserch": _vm.timeserchproducts,
      "from": _vm.from_products,
      "to": _vm.to_products,
      "currentUser": _vm.currentUser
    },
    on: {
      "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
      "FindRecordsBetweenDate": _vm.FindRecordsHeader,
      "getUsersDebance": _vm.getUsersDebance,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1), _vm.currentUser && _vm.currentUser.type && _vm.currentUser.type == 'Seller' ? _c('div', {
    staticClass: "w-full lg:w-1/2 md:w-1/2 xl:w-1/3 px-4"
  }, [_c('card-v1', {
    attrs: {
      "statSubtitle": "Expeditions received",
      "filter": "true",
      "filterseller": "true",
      "action": "Expeditions",
      "timeserch": _vm.timeserchexpeditions,
      "loading": _vm.loadingexpeditions,
      "color": "#ee8b0c",
      "cl": "exp",
      "datas": _vm.dataexpeditions,
      "dataChart": _vm.dataexpeditions.data,
      "products": _vm.products,
      "product": _vm.product_expeditions,
      "from": _vm.from_expeditions,
      "to": _vm.to_expeditions,
      "currentUser": _vm.currentUser
    },
    on: {
      "getProducts": _vm.getProducts,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "w-full lg:w-full md:w-full xl:w-1/2 px-4"
  }, [_c('card-v2', {
    attrs: {
      "statSubtitle": _vm.$t('Orders Summary'),
      "statSubtitleRate": _vm.$t('Callcenter/ Rate'),
      "filter": "true",
      "filterseller": "true",
      "action": "OrdersSummary",
      "timeserch": _vm.timeserchorderssummary,
      "loading": _vm.loadingorderssummary,
      "color": "#ee8b0c",
      "cl": "exp",
      "datas": _vm.dataOrdersSummary,
      "dataChart": _vm.dataOrdersSummary.dataChart,
      "products": _vm.products,
      "sellers": _vm.sellers,
      "product": _vm.product_orderssummary,
      "seller": _vm.features['multi-sellers'].isActive ? _vm.seller_orderssummary : _vm.mainSeller,
      "from": _vm.from_orderssummary,
      "to": _vm.to_orderssummary,
      "currentUser": _vm.currentUser
    },
    on: {
      "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
      "FindRecordsBetweenDate": _vm.FindRecordsHeader,
      "getUsersDebance": _vm.getUsersDebance,
      "getProducts": _vm.getProducts,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1), _c('div', {
    staticClass: "w-full lg:w-full md:w-full xl:w-1/2 px-4"
  }, [_c('card-v2', {
    attrs: {
      "statSubtitle": _vm.$t('Shippings Summary'),
      "statSubtitleRate": _vm.$t('Livraison/ Rate'),
      "filter": "true",
      "filterseller": "true",
      "action": "ShippingsSummary",
      "timeserch": _vm.timeserchshippingssummary,
      "loading": _vm.loadingshippingssummary,
      "color": "#ee8b0c",
      "cl": "exp",
      "datas": _vm.dataShippingsSummary,
      "dataChart": _vm.dataShippingsSummary.dataChart,
      "products": _vm.products,
      "sellers": _vm.sellers,
      "product": _vm.product_shippingssummary,
      "seller": _vm.features['multi-sellers'].isActive ? _vm.seller_shippingssummary : _vm.mainSeller,
      "from": _vm.from_shippingssummary,
      "to": _vm.to_shippingssummary,
      "currentUser": _vm.currentUser
    },
    on: {
      "ChangeDatevalue": _vm.ChangeTypeDateRevenuHeader,
      "FindRecordsBetweenDate": _vm.FindRecordsHeader,
      "getUsersDebance": _vm.getUsersDebance,
      "getProducts": _vm.getProducts,
      "CheckSellerProducts": _vm.CheckSellerProducts,
      "removedata": _vm.removedata
    }
  })], 1)])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }