var render = function render(){
  var _vm$selectedProduct;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('div', {
    staticClass: "bg-gray-700 h-12 w-12 rounded flex items-center justify-center",
    staticStyle: {
      "left": "50%",
      "top": "3%",
      "position": "fixed",
      "z-index": "9999"
    }
  }, [_c('svg', {
    staticClass: "animate-spin h-6 w-6 text-white",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "12",
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 3a9 9 0 1 0 9 9"
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "flex justify-between items-end my-5 px-6 flex-wrap gap-6"
  }, [_c('div', [_c('h1', {
    staticClass: "text-xl font-semibold"
  }, [_vm._v("Dashboard")]), _vm.selectedProduct ? _c('div', {
    staticClass: "flex items-center text-lg gap-4"
  }, [_vm._v(" Analytics and insights for Product: "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v(_vm._s((_vm$selectedProduct = _vm.selectedProduct) === null || _vm$selectedProduct === void 0 ? void 0 : _vm$selectedProduct.name))]), _c('router-link', {
    attrs: {
      "to": "/products/".concat(_vm.selectedProduct._id, "/analytics")
    }
  }, [_c('Button', {
    staticClass: "p-button-rounded p-button-outlined",
    attrs: {
      "icon": "pi pi-link"
    }
  })], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "flex gap-4 flex-wrap"
  }, [_vm.productTags.length ? _c('Dropdown', {
    staticClass: "w-full md:w-64",
    attrs: {
      "optionLabel": "label",
      "options": _vm.productTags,
      "showClear": "",
      "placeholder": "Select Product Tag",
      "disabled": _vm.isLoading
    },
    model: {
      value: _vm.selectedProductTag,
      callback: function callback($$v) {
        _vm.selectedProductTag = $$v;
      },
      expression: "selectedProductTag"
    }
  }) : _vm._e(), _c('Dropdown', {
    staticClass: "w-full md:w-64",
    attrs: {
      "options": _vm.products,
      "optionLabel": "name",
      "showClear": "",
      "placeholder": "Select a Product",
      "disabled": _vm.isLoading
    },
    model: {
      value: _vm.selectedProduct,
      callback: function callback($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  }), _c('Vue2DatePicker', {
    staticClass: "w-full md:w-64",
    attrs: {
      "range": "",
      "placeholder": "Select date range",
      "disabled-date": _vm.notAfterToday,
      "shortcuts": _vm.SHORTCUTS,
      "disabled": _vm.isLoading
    },
    model: {
      value: _vm.selectedDateRange,
      callback: function callback($$v) {
        _vm.selectedDateRange = $$v;
      },
      expression: "selectedDateRange"
    }
  })], 1)]), _c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5 px-6"
  }, [_c('Rate', {
    staticClass: "w-full p-2",
    attrs: {
      "type": "Total Orders",
      "rate": _vm.ordersRateDetails.rates.total.rate,
      "count": _vm.ordersRateDetails.rates.total.current,
      "series": _vm.ordersRateDetails.series.total,
      "period": _vm.period
    }
  }), _c('Rate', {
    staticClass: "w-full p-2",
    attrs: {
      "type": "Delivered Orders",
      "rate": _vm.ordersRateDetails.rates.delivered.rate,
      "count": _vm.ordersRateDetails.rates.delivered.current,
      "series": _vm.ordersRateDetails.series.delivered,
      "period": _vm.period
    }
  }), _c('Rate', {
    staticClass: "w-full p-2",
    attrs: {
      "type": "Confirmed Orders",
      "rate": _vm.ordersRateDetails.rates.confirmed.rate,
      "count": _vm.ordersRateDetails.rates.confirmed.current,
      "series": _vm.ordersRateDetails.series.confirmed,
      "period": _vm.period
    }
  })], 1), _c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5 px-6"
  }, [_c('div', {
    staticClass: "p-3"
  }, [_c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow p-6"
  }, [_c('h2', {
    staticClass: "text-xl font-semibold tracking-wide"
  }, [_vm._v(" Profits per delivery ")]), _c('div', {
    staticClass: "flex items-center gap-4 justify-between"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black",
    class: {
      'text-green-500': _vm.costPerDeliveryDetails.costs.profitsPerDelivery > 0,
      'text-red-500': _vm.costPerDeliveryDetails.costs.profitsPerDelivery < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costPerDeliveryDetails.costs.profitsPerDelivery)) + " ")])])])]), _c('div', {
    staticClass: "p-3"
  }, [_c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow p-6"
  }, [_c('h2', {
    staticClass: "text-xl font-semibold tracking-wide"
  }, [_vm._v("Total Profits")]), _c('div', {
    staticClass: "flex items-center gap-4 justify-between"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black",
    class: {
      'text-green-500': _vm.costPerDeliveryDetails.costs.totalProfits > 0,
      'text-red-500': _vm.costPerDeliveryDetails.costs.totalProfits < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costPerDeliveryDetails.costs.totalProfits)) + " ")])])])]), _c('div', {
    staticClass: "p-3"
  }, [_c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow p-6"
  }, [_c('h2', {
    staticClass: "text-xl font-semibold tracking-wide"
  }, [_vm._v(" Return of investment ")]), _c('div', {
    staticClass: "flex items-center gap-4 justify-between"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black",
    class: {
      'text-green-500': _vm.costPerDeliveryDetails.costs.roi > 0,
      'text-red-500': _vm.costPerDeliveryDetails.costs.roi < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.costPerDeliveryDetails.costs.roi) + " % ")])])])]), _c('div', {
    staticClass: "p-3"
  }, [_c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow p-6"
  }, [_c('h2', {
    staticClass: "text-xl font-semibold tracking-wide"
  }, [_vm._v(" Average order value ")]), _c('div', {
    staticClass: "flex items-center gap-4 justify-between"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black",
    class: {
      'text-green-500': _vm.costPerDeliveryDetails.costs.aov > 0,
      'text-red-500': _vm.costPerDeliveryDetails.costs.aov < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costPerDeliveryDetails.costs.aov)) + " ")])])])]), _c('div', {
    staticClass: "p-3"
  }, [_c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow p-6"
  }, [_c('h2', {
    staticClass: "text-xl font-semibold tracking-wide"
  }, [_vm._v("Revenues")]), _c('div', {
    staticClass: "flex items-center gap-4 justify-between"
  }, [_c('span', {
    staticClass: "text-3xl font-bold text-black",
    class: {
      'text-green-500': _vm.costPerDeliveryDetails.costs.revenues > 0,
      'text-red-500': _vm.costPerDeliveryDetails.costs.revenues < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costPerDeliveryDetails.costs.revenues)) + " ")])])])])]), _c('div', {
    staticClass: "grid grid-cols-1 lg:grid-cols-2 mt-5 px-6"
  }, [_c('div', {}, [_c('div', {
    staticClass: "p-6 mb-6 space-y-4 md:space-y-0 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
  }, [_c('div', [_c('h2', {
    staticClass: "text-xl font-semibold tracking-wide mb-4"
  }, [_vm._v(" Cost per delivery ")]), _c('div', {
    staticClass: "flex justify-between gap-6 flex-wrap mb-6"
  }, [_c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Total")]), _c('span', {
    staticClass: "text-2xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costPerDeliveryDetails.costs.CPDTotal)) + " ")])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("ADS")]), _c('span', {
    staticClass: "text-2xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costPerDeliveryDetails.costs.CPDAds)) + " ")])]), _c('div', [_c('h3', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Charges")]), _c('span', {
    staticClass: "text-2xl font-bold text-black"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.costPerDeliveryDetails.costs.CPDCharges)) + " ")])])]), _c('VueApexCharts', {
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm._chartOptions,
      "series": _vm.costPerDeliverySeries
    }
  })], 1)])]), _c('div', [_vm._t("left")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }