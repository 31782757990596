var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon icon-tabler icon-tabler-coin",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": _vm.size,
      "height": _vm.size,
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": _vm.color,
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 1 0 0 4h2a2 2 0 1 1 0 4h-2a2 2 0 0 1 -1.8 -1"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 7v10"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }