var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-3"
  }, [_c('div', {
    staticClass: "bg-white border border-gray-200 rounded-lg shadow"
  }, [_c('div', {
    staticClass: "p-6 pb-2"
  }, [_c('h5', {
    staticClass: "text-black font-semibold text-md tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.type) + " ")]), _c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', {
    staticClass: "font-normal text-gray-500 text-md tracking-wider flex items-center gap-2"
  }, [_c('span', {
    staticClass: "flex items-center",
    class: {
      'text-green-500': _vm.rate > 0,
      'text-red-700': _vm.rate < 0
    }
  }, [_vm.rate < 0 ? _c('ArrowDownRight', {
    staticClass: "text-xs w-4 h-4"
  }) : _vm.rate > 0 ? _c('ArrowUpRight', {
    staticClass: "text-xs w-4 h-4"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.rate.toFixed(2)) + "% ")], 1), _vm._v(" " + _vm._s(_vm.period) + " ")]), _c('span', {
    staticClass: "text-center text-4xl font-semibold",
    class: {
      'text-green-500': _vm.rate > 0,
      'text-red-700': _vm.rate < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.count) + " ")])])]), _c('VueApexChart', {
    ref: "realtimeChart",
    attrs: {
      "options": _vm.CHART_OPTIONS,
      "series": [{
        data: _vm.series,
        name: _vm.type
      }],
      "type": "bar",
      "height": "100"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }