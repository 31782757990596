<template>
  <div>
    <div
      class="flex-grow flex flex-col m-2 p-6 bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
    >
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-gray-800">
            {{ title }}
          </h3>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-5">
        <div
          v-if="userType == 'Admin' || userType == 'CEO'"
          class="relative w-full px-4 max-w-full flex-grow flex-1"
        >
          <div
            class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="category" class="bg-white text-gray-700 px-1"
                  >{{ $t("seller") }} :
                  <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <v-select
                v-model="timeserchselleruser"
                :options="sellers"
                label="fullName"
                @input="ChangeSellerDelivery"
              ></v-select>
            </p>
          </div>
        </div>
        <div class="relative w-1/2 pl-4 mt-2 flex float-right">
          <div
            class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="status" class="bg-white text-gray-700 px-1"
                  >{{ $t("date") }} :
                  <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <select
                id="type"
                autocomplete="type"
                @change="ChangeTypeDateDelivery($event)"
                v-model="timeserchtype"
                class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
              >
                >
                <option class="bg-white text-gray-600" value="today">
                  {{ $t("today") }}
                </option>
                <option class="bg-white text-gray-600" value="thisweek">
                  {{ $t("this_week") }}
                </option>
                <option class="bg-white text-gray-600" value="lastweek">
                  {{ $t("last_week") }}
                </option>
                <option class="bg-white text-gray-600" value="thismonth">
                  {{ $t("this_month") }}
                </option>
                <option class="bg-white text-gray-600" value="lastmonth">
                  {{ $t("last_month") }}
                </option>
                <option class="bg-white text-gray-600" value="thisyear">
                  {{ $t("this_year") }}
                </option>
                <option value="betweendate" class="bg-white text-gray-600">
                  {{ $t("between_two_date") }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <div
          v-if="timeserchtype == 'betweendate'"
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <div class="inline-block mt-4">
            <div class="inline-block mt-2 w-2/5 pr-1">
              <div
                class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
              >
                <div
                  class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                >
                  <p>
                    <label for="date" class="bg-white text-gray-700 px-1"
                      >From <span class="text-blue-500">*</span></label
                    >
                  </p>
                </div>
                <p>
                  <input
                    v-model="fromDate"
                    class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded"
                    id="from"
                    type="date"
                  />
                </p>
              </div>
            </div>
            <div class="inline-block mt-2 w-2/5 pr-1">
              <div
                class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
              >
                <div
                  class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                >
                  <p>
                    <label for="date" class="bg-white text-gray-700 px-1"
                      >To <span class="text-blue-500">*</span></label
                    >
                  </p>
                </div>
                <p>
                  <input
                    v-model="toDate"
                    class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded"
                    id="to"
                    type="date"
                  />
                </p>
              </div>
            </div>
            <div class="inline-block mt-2 w-1/5 pr-1">
              <button
                @click="FindRecordShippings()"
                :disabled="fromDate == null || toDate == null"
                class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded"
              >
                Find
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="chart">
        <apexchart
          type="pie"
          width="380"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
//import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  props: [
    "dataDelivery",
    "options",
    "labelstringd",
    "timeserch",
    "timeserchseller",
    "title",
    "typedelivery",
    "userType",
    "count",
    "from",
    "to",
  ],
  data() {
    return {
      myChart: "",
      timeserchtype: this.timeserch,
      timeserchselleruser: this.timeserchseller,
      sellers: [],
      series: this.dataDelivery,
      fromDate: this.from,
      toDate: this.to,
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
          id: "chart",
          toolbar: {
            show: false,
          },
        },
        labels: ["Shipped", "Delivered", "Cancelled"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  async mounted() {
    await this.getSellers();
  },
  methods: {
    FindRecordShippings: function () {
      this.$emit("FindRecordShippings", this.fromDate, this.toDate);
    },
    ChangeTypeDateDelivery: function (val) {
      this.$emit("ChangeTypeDateDelivery", val);
    },
    ChangeSellerDelivery: function (val) {
      this.$emit("ChangeSellerDelivery", val);
    },
    async getSellers() {
      const res = await this.$server.search("users", {
        type: "Seller",
        limit: 4000,
      });
      if (res.content.results) {
        this.sellers = res.content.results;
        this.sellers.unshift("All");
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
body {
  font-family: "IBM Plex Mono", sans-serif;
}
[x-cloak] {
  display: none;
}
.vs__dropdown-toggle {
  border: none;
}
.line {
  background: repeating-linear-gradient(
    to bottom,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 8%
  );
}
.tick {
  background: repeating-linear-gradient(
    to right,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 5%
  );
}
</style>
